export const mockProps = {
  value: 'text input',
  name: 'inputname',
  label: 'Input label',
  placeholder: 'Text hint',
  size: 'md',
  rows: '4',
  block: true,
  disabled: false,
  error: false,
  labelHidden: false,
};